<template>
    <div class="coming-soon-page">
        <video autoplay muted loop class="background-video">
            <source src="@/assets/videos/SoonBack.mp4" type="video/mp4">
            Tu navegador no soporta videos.
        </video>
        <div class="content">
            <img src="@/assets/logo.png" alt="Logo" class="logo" />
            <h1 class="title">Muy Pronto</h1>
            <p class="description">Estamos trabajando en algo increíble. ¡Mantente atento a nuestras novedades!</p>
            <div class="countdown">
                <div class="time-box">
                    <span class="time" v-text="days"></span>
                    <span class="label">Días</span>
                </div>
                <div class="time-box">
                    <span class="time" v-text="hours"></span>
                    <span class="label">Horas</span>
                </div>
                <div class="time-box">
                    <span class="time" v-text="minutes"></span>
                    <span class="label">Minutos</span>
                </div>
                <div class="time-box">
                    <span class="time" v-text="seconds"></span>
                    <span class="label">Segundos</span>
                </div>
            </div>
            <div class="social-icons">
                <a href="https://facebook.com" target="_blank" class="social-icon">
                    <i class="bi bi-facebook"></i>
                </a>
                <a href="https://instagram.com" target="_blank" class="social-icon">
                    <i class="bi bi-instagram"></i>
                </a>
                <a href="https://twitter.com" target="_blank" class="social-icon">
                    <i class="bi bi-twitter"></i>
                </a>
            </div>
            <button class="btn btn-notify">Notifícame</button>
            <div class="back-link mt-3">
                <a href="https://tscenter.com.co/" class="back-link-text">
                    <i class="bi bi-arrow-left"></i> Atrás
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ComingSoonPage',
    data() {
        return {
            targetDate: new Date('2024-12-01T00:00:00').getTime(),
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
    },
    methods: {
        updateCountdown() {
            const now = new Date().getTime();
            const distance = this.targetDate - now;

            this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
            this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
        }
    },
    mounted() {
        this.updateCountdown();
        setInterval(this.updateCountdown, 1000);
    }
};
</script>

<style scoped>
.coming-soon-page {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    padding: 20px;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.content {
    max-width: 600px;
    background: rgba(255, 255, 255, 0.85);
    border-radius: 15px;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.logo {
    width: 120px;
    margin-bottom: 20px;
}

.title {
    font-size: 2.5rem;
    color: #a50034;
    margin-bottom: 10px;
}

.description {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 30px;
}

.countdown {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.time-box {
    margin: 0 10px;
}

.time {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
}

.label {
    display: block;
    font-size: 0.9rem;
    color: #666;
}

.social-icons {
    margin-bottom: 30px;
}

.social-icon {
    margin: 0 10px;
    color: #333;
    font-size: 1.5rem;
    transition: color 0.3s;
}

.social-icon:hover {
    color: #a50034;
}

.btn-notify {
    background-color: #a50034;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn-notify:hover {
    background-color: #8c0102;
}

.back-link {
    margin-top: 20px;
}

.back-link-text {
    color: #007bff;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
}

.back-link-text:hover {
    color: #0056b3;
}

@media (max-width: 576px) {
    .title {
        font-size: 2rem;
    }

    .description {
        font-size: 1rem;
    }

    .time {
        font-size: 1.5rem;
    }

    .social-icon {
        font-size: 1.2rem;
    }
}
</style>